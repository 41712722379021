import React from "react";
import aiActions from "@web/redux/ai/actions";
import {useDispatch, useSelector} from "react-redux";
import {chatAppAgents} from "@oven/app-thescribe/src/dashboard.config";

const StreamingDraftListener = (props) => {
  const dispatch = useDispatch();
  const activeDraftPath =
    useSelector((state) =>
      state.AI.draft?.draftPath);

  React.useEffect(() => {
    const answer =
      props.chatStream.answers.find((answer) =>
        chatAppAgents.emailWriter.agentIds.includes(answer.agentId));

    console.log("StreamingDraftListener:useEffect:answer:", answer);

    answer &&
    answer.streamParams &&
    answer.streamParams.draftPath &&
    activeDraftPath !== answer.streamParams.draftPath &&
    dispatch(aiActions.setActiveEmailDraft(answer.streamParams.draftPath))
  }, [props.chatStream]);

  return <React.Fragment />;
}

export default StreamingDraftListener;
