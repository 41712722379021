import React from 'react';
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {useSelector} from "react-redux";

ItemData.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  dataField: PropTypes.string,
};

function ItemData({component, dataField, ...props}) {
  const item = useSelector((state) => {
    return state.Content.data?.[props.path];
  });

  const databaseItem =
    useDocumentFirestore(item ? "" : props.path);

  if (!item && !databaseItem) {
    return null;
  }

  return React.createElement(component, {
    ...props,
    [dataField || "data"]: item || databaseItem,
  });
}

export default ItemData;
