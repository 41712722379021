import React from "react";
import PropTypes from "prop-types";
import {Button, Typography} from "antd";
import {DownArrowOutlined} from "@web/assets/icons";

Toggle.propTypes = {
  defaultExpanded: PropTypes.bool,
  expand: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  rows: PropTypes.number,
  hideTriggerOnExpand: PropTypes.bool,
  showExpandCaret: PropTypes.bool,
};

function Toggle(props) {
  const [expanded, setExpanded] = React
  .useState(props.defaultExpanded ?? false);

  React.useEffect(() => {
    typeof props.expand === 'boolean' &&
    setExpanded(props.expand);
  }, [props.expand]);

  const onClickTrigger = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
    console.log("Toggle:onClickTrigger", expanded);
    return false;
  }

  return <div className={"toggle-wrapper space space-col " + (expanded ? "expanded" : "collapsed")}>
    {!props.rows &&
      <span onClick={onClickTrigger} className={props.hideTriggerOnExpand ? "relative toggle-hide-expanded" : "relative"}>
        {!!props.showExpandCaret && <Button
          ghost
          className="toggle-collapse-button icon-btn icon-right"
          onClick={onClickTrigger}
        >
          <DownArrowOutlined />
        </Button>}
        {props.trigger}
      </span>
    }
    {props.rows ?
      expanded ?
        <Typography.Paragraph>{props.children}</Typography.Paragraph> :
        <Typography.Paragraph
          onClick={onClickTrigger}
          ellipsis={{rows: props.rows}}
        >{props.children}
        </Typography.Paragraph> :
      expanded &&
      <div className="relative">
        {props.hideTriggerOnExpand &&
          expanded &&
          <Button
            ghost
            className="toggle-collapse-button icon-btn icon-right"
            onClick={onClickTrigger}
          >
            <DownArrowOutlined />
          </Button>
        }
        {props.children}
      </div>
    }</div>
}

export default Toggle;
