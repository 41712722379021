
/** @typedef {String<('today'|'threads'|'calendars'|'activity'|'files'|'voice'|'website'|'payments'|'contacts'|'todos'|'trello'|'deliveries'|'workflow'|'chat'|'store'|'source'|'datasets'|'quote')>} dashboard.ServiceId */

/**
 * @typedef {Object} dashboard.SolutionsServiceConfig
 * @property {dashboard.ServiceId} key
 * @property {string} solutionId
 * @property {string} label
 * @property {string} path
 * @property {React.ReactNode} icon
 * @property {React.ReactNode} children
 * @property {React.ReactNode} search
 * @property {React.ReactNode} item
 * @property {string[]} sourceIds
 * @property {boolean} filter
 * @property {Object} chat
 * @property {string} chat.agentId
 * @property {string} chat.agentName
 * @property {function} chat.getCollectionPath
 */

import IconTip from "@ai-antd/components/IconTip";
import {
  CPUIcon,
  CustomerIcon, DatabaseIcon,
  EmailIconOutlined, FeatherIcon, GlobeIcon, HomeOutlined,
  LayersIcon,
  PaymentIcon, StarIcon,
  TruckIcon,
  UserOutlined,
} from "@web/assets/icons";
import ServiceData from "@web/ui/containers/ServiceData";
import ContactsList from "@ai-antd/components/ContactsList";
import ContactDetailsWrapper from "@ai-antd/components/ContactDetailWrapper";
// import EmailsData from "@web/ui/containers/EmailsData";
import ThreadsList from "@ai-antd/components/ThreadsList";
import ContentDetailWrapper from "@ai-antd/components/ContentDetailWrapper";
// import FilesData from "@web/ui/containers/FilesData";
import FileList from "@ai-antd/components/FileList";
// import FileDetailsWrapper from "@ai-antd/components/FileDetailsWrapper";
// import PaymentsData from "@web/ui/containers/PaymentsData";
import PaymentsList from "@ai-antd/components/PaymentsList";
// import PaymentDetail from "@ai-antd/components/PaymentDetail";
import DeliveriesList from "@ai-antd/components/DeliveriesList";
// import DeliveryDetail from "@ai-antd/components/DeliveryDetail";
import ProductsList from "@ai-antd/components/ProductsList";
import Datasets from "@ai-antd/components/Datasets";
import Analyst from "@ai-antd/page/Analyst";
import dayjs from "dayjs";
import React from "react";
import Progress from "@ai-antd/page/Progress";
import ChatPage from "@ai-antd/page/ChatPage";
// import Icon from "@ai-antd/components/Icon";
// import ContactCard from "@ai-antd/components/ContactCard";
// import ContactInfoCard from "@ai-antd/components/ContactInfoCard";
import ContactExtra from "@ai-antd/components/ContactExtra";
// import EmailsCard from "@ai-antd/components/EmailsCard";
// import MessageCard from "@ai-antd/components/MessageCard";

/** @typedef {Object<dashboard.ServiceId, dashboard.SolutionsServiceConfig>} dashboard.SolutionsServicesConfig */
const services = {
  // today: {
  //   key: "today",
  //   solutionId: 'activity',
  //   label: <IconTip key="today" title="Today" placement="right">
  //     <div><HomeOutlined className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/today",
  //   icon: <HomeOutlined className="menu-icon"/>,
  //   children: null, // <Today/>,
  //   search: null, // <TodayFiltered/>,
  //   item: null,
  //   sourceIds: [],
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("activity:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Activity",
  //         title: ""
  //       };
  //     },
  //     getActions: async (user, {path}) => {
  //       const prompts = [];
  //       prompts.push({
  //         text: "urgent items",
  //         agentId: "ai-assistant",
  //       });
  //       prompts.push({
  //         text: "yesterday's summary",
  //         agentId: "ai-assistant",
  //       });
  //       return prompts;
  //     },
  //   },
  // },
  // filter: {
  //   key: "filter",
  //   solutionId: 'activity',
  //   label: <IconTip key="filter" title="Filter" placement="right">
  //     <div><SearchIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/filter",
  //   icon: <SearchIcon className="menu-icon"/>,
  //   children: null, //<FilterList />,
  //   item: null,
  //   filter: {active: false},
  // },
  progress: {
    key: "progress",
    solutionId: 'activity',
    label: <IconTip key="act" title="Act" placement="right">
      <div><HomeOutlined className="menu-icon"/></div>
    </IconTip>,
    title: "Progress",
    path: "/progress",
    icon: <UserOutlined className="menu-icon"/>,
    sourceIds: [],
    // children: <ServiceData
    //   serviceId="contacts"
    //   component={ContactsList}
    // />,
    children: null,
    item: Progress,
    filter: {active: true, desktop: true, mobile: false},
    chat: {
      agentId: "ai-progress",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) =>
        item?.path ?
          `${item.path}/${agentId}` :
          `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        return {
          path: item?.path,
          label: "Contact",
        };
      },
      getActions: async (user) => {
        return [];
      }
    },
  },
  chat: {
    key: "chat",
    solutionId: 'activity',
    label: <IconTip key="ai" title="AI Manager" placement="right">
      <div><StarIcon className="menu-icon"/></div>
    </IconTip>,
    path: "/chat",
    icon: <FeatherIcon className="menu-icon"/>,
    children: <ChatPage></ChatPage>,
    // search: <ChatWrapper/>,
    // item: ChatDetail,
    sourceIds: [],
    filter: {activity: false, active: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("chat:getContext", item);
        return {
          path: item?.path,
          label: "Chat",
          // title: item?.file?.other?.displayName || item?.file?.originFilename,
        };
      },
    },
  },
  contacts: {
    key: "contacts",
    solutionId: 'activity',
    label: <IconTip key="contacts" title="Contacts" placement="right">
      <div><UserOutlined className="menu-icon"/></div>
    </IconTip>,
    path: "/activity",
    icon: <UserOutlined className="menu-icon"/>,
    sourceIds: ["gmail", "outlook", "web"],
    children: <ServiceData
      serviceId="contacts"
      component={ContactsList}
    />,
    item: ContactDetailsWrapper,
    extra: ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) =>
        item?.path ?
          `${item.path}/${agentId}` :
          `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        return {
          path: item?.path,
          label: "Contact",
        };
      },
      getActions: async (user, {contact}) => {
        const prompts = [];
        switch (true) {
          case contact?.email &&
          (!contact.email.toLowerCase().includes("noreply") &&
            !contact.email.toLowerCase().includes("no_reply") &&
            !contact.email.toLowerCase().includes("no-reply")) &&
          contact.email !== user?.email: {
            prompts.push({
              text: "Send email",
            });
            break;
          }
          default:
            break;
        }
        return prompts;
      }
    },
  },
  threads: {
    key: "threads",
    solutionId: 'activity',
    // card: MessageCard,
    label: <IconTip key="threads" title="Threads" placement="right">
      <div><EmailIconOutlined className="menu-icon"/></div>
    </IconTip>,
    path: "/threads",
    icon: <EmailIconOutlined className="menu-icon"/>,
    children: <ServiceData
      serviceId="threads"
      component={ThreadsList}
    />,
    // children: <EmailsData key="threads" component={ThreadsList} />,
    // search: <EmailsSearchData key="threads-filtered"><ThreadsList/></EmailsSearchData>,
    item: ContentDetailWrapper,
    sourceIds: ["gmail", "outlook"],
    filter: {active: true, desktop: true, mobile: true},
    overlayChat: true,
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) =>
        item?.path ?
          `${item.path}/${agentId}` :
          `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        // console.log("threads:getContext", item);
        return {
          path: item?.path,
          label: "Thread",
          title: item.message.subject,
        };
      },
      getActions: async (user, {message}) => {
        const actions = [];
        if (!message) {
          return actions;
        }
        if (!message.from) {
          return actions;
        }
        switch (true) {
          case message.from?.email &&
          ((!message.from.email.toLowerCase().includes("noreply") &&
              !message.from.email.toLowerCase().includes("no_reply") &&
              !message.from.email.toLowerCase().includes("no-reply")) ||
            message.replyTo?.email) &&
          message.from.email !== user?.email: {
            actions.push({
              text: "Reply",
              agentId: "ai-email-assistant",
            });
            break;
          }
          default:
            break;
        }
        actions.push({
          text: "Forward",
          agentId: "ai-email-assistant",
        });
        actions.push({
          text: "summarize email",
          agentId: "ai-assistant",
        });
        return actions;
      }
    },
  },
  // calendars: {
  //   key: "calendars",
  //   solutionId: 'activity',
  //   label: <IconTip key="calendar" title="calendar" placement="right">
  //     <div><CalendarIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/calendar",
  //   icon: <CalendarIcon className="menu-icon"/>,
  //   children: <CalendarData key="calendar" component={CalendarEventsList}/>,
  //   item: CalendarEventDetailWrapper,
  //   sourceIds: ["gmail", "outlook", "calendar"],
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("calendars:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Calendar",
  //         // title: item.data.name,
  //       };
  //     },
  //   }
  // },
  // activity: {
  //   key: "activity",
  //   solutionId: 'activity',
  //   label: <IconTip key="feed" title="Feed" placement="right">
  //     <div><HashIcon/></div>
  //   </IconTip>,
  //   path: "/activity",
  //   icon: <Icon name="ActivityIcon" className="menu-icon"/>,
  //   children: null, // <FeedData key="feed"><EventsList/></FeedData>,
  //   sourceIds: [],
  //   item: ActivityDetailsWrapper,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("activity:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Activity",
  //         // title: item.data.name,
  //       };
  //     },
  //   },
  // },
  files: {
    key: "files",
    solutionId: 'activity',
    label: <IconTip key="files" title="Files" placement="right">
      <div><LayersIcon className="menu-icon"/></div>
    </IconTip>,
    path: "/files",
    icon: <LayersIcon className="menu-icon"/>,
    sourceIds: ["drive", "files", "upload", "sheets"],
    children: <ServiceData
      serviceId="files"
      component={FileList}
    />,
    // item: FileDetailsWrapper,
    item: ContentDetailWrapper,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) =>
        item?.path ?
          `${item.path}/${agentId}` :
          `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: ({path, file}) => {
        return {
          path: path,
          label: "File",
          title: file?.other?.displayName || file?.originFilename,
        };
      },
      getActions: async (user, {file}) => {
        if (!file) {
          return [];
        }
        if (file.alert) {
          return [];
        }
        const contentType = file.mimeType.split("/")[0].toLowerCase();
        const actions = [];
        switch (true) {
          case contentType === "audio": {
            if (!file.transcriptPath) {
              actions.push({
                text: "transcribe audio",
                agentId: "ai-audio-manager",
              });
            }
            if (file.transcriptPath && file.transcriptTs) { // transcript is available and ready
              actions.push({
                text: "search transcript",
                agentId: "ai-audio-manager",
              });
            }
            break;
          }
          case file.mimeType === "application/pdf":
            actions.push({
              text: "summarize document",
            });
            break;
          case file.mimeType === "application/json":
          case file.mimeType === "text/csv":
            if (!file.datasetPath) {
              actions.push({
                text: "Create dataset for analysis",
                agentId: "ai-analyst",
              });
            }
            break;
          default:
            break;
        }
        return actions;
      }
    },
  },
  // phone: {
  //   key: "phone",
  //   solutionId: 'activity',
  //   label: <IconTip key="voice" title="Voice" placement="right">
  //     <div><PhoneCallIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/phone",
  //   icon: <Icon name="PhoneCallIcon" className="menu-icon" size={20}/>,
  //   children: <VoiceList />,
  //   sourceIds: ["spokePhone", "recorder", "theScribe"],
  //   item: null,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //   },
  //   getContext: (item) => {
  //     console.log("voice:getContext", item);
  //     return {
  //       path: item?.path,
  //       label: "Voice",
  //       // title: "",
  //     };
  //   },
  //   getActions: async (user, {path}) => {
  //     const prompts = [];
  //     prompts.push({
  //       text: "about phone",
  //       agentId: "ai-phone-manager",
  //     });
  //     return prompts;
  //   }
  // },
  // website: {
  //   key: "website",
  //   solutionId: 'analyst',
  //   label: <IconTip key="website" title="Sites" placement="right">
  //     <div><WebsiteIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/website",
  //   icon: <Icon name="WebsiteIcon" className="menu-icon" size={20}/>,
  //   children: <WebsitesList/>,
  //   sourceIds: ["scraper"],
  //   item: null,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Analyst",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("website:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Website",
  //         // title: item?.file?.other?.displayName || item?.file?.originFilename,
  //       };
  //     },
  //   },
  // },
  payments: {
    key: "payments",
    solutionId: 'activity',
    label: <IconTip key="payments" title="Payments" placement="right">
      <div><PaymentIcon className="menu-icon" size={23}/></div>
    </IconTip>,
    path: "/payments",
    icon: <PaymentIcon className="menu-icon"/>,
    children: <ServiceData
      serviceId="payments"
      component={PaymentsList}
    />,
    sourceIds: ["stripe", "spreadsheet", "theScribe"],
    // item: PaymentDetail,
    item: ContentDetailWrapper,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) =>
        item?.path ?
          `${item.path}/${agentId}` :
          `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("payments:getContext", item);
        return {
          path: item?.path,
          label: "Payment",
          title: Object.values(item?.payment?.members || {}).map((member) => member.displayName).join() + " on " + dayjs(item?.payment?.updatedTs).format("MMMM DD"),
        };
      },
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "About payments",
          agentId: "ai-assistant",
        });
        return prompts;
      }
    },
  },
  // todos: {
  //   key: "todos",
  //   solutionId: 'activity',
  //   label: <IconTip key="todos" title="Todos" placement="right">
  //     <div><TodoIcon size={20}/></div>
  //   </IconTip>,
  //   path: "/todos",
  //   icon: <TodoIcon className="menu-icon" size={24}/>,
  //   children: <TodosData component={TodosList} />,
  //   sourceIds: ["trello", "asana", "gmail", "outlook", "voice"],
  //   item: TodoDetailWrapper,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("todos:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Todo",
  //         // title: item?.file?.other?.displayName || item?.file?.originFilename,
  //       };
  //     },
  //   },
  // },
  // trello: {
  //   key: "trello",
  //   solutionId: 'activity',
  //   label: <IconTip key="trello" title="Trell" placement="left">
  //     <div><TrelloIcon size={20}/></div>
  //   </IconTip>,
  //   path: "/trello",
  //   icon: <TrelloIcon className="menu-icon" size={24}/>,
  //   children: <TasksList list={[]} title="Projects" />,
  //   sourceIds: ["trello"],
  //   item: TrelloDetailWrapper,
  //   filter: {active: false},
  // },
  deliveries: {
    key: "deliveries",
    solutionId: 'activity',
    label: <IconTip key="shipping" title="Shipping" placement="right">
      <div><TruckIcon style={{position: "relative", top: "1px"}}/></div>
    </IconTip>,
    path: "/deliveries",
    icon: <TruckIcon className="menu-icon" />,
    children: <ServiceData
      serviceId="deliveries"
      component={DeliveriesList}
    />,
    sourceIds: ["shipping"],
    item: ContentDetailWrapper,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("payments:getContext", item);
        return {
          path: item?.path,
          label: "Delivery",
          title: Object.values(item?.delivery?.members || {}).map((member) => member.displayName).join() + " on " + dayjs(item?.payment?.updatedTs).format("MMMM DD"),
        };
      },
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "today's deliveries",
          agentId: "ai-assistant",
        });
        return prompts;
      }
    },
  },
  automations: {
    key: "automations",
    solutionId: 'activity',
    label: <IconTip key="add" title="Automations" placement="right">
      <div><CPUIcon size={22} style={{position: "relative", top: "1px"}}/></div>
    </IconTip>,
    path: "/automations",
    icon: <CPUIcon size={22} className="menu-icon"/>,
    children: null, // <FeatureList/>,
    sourceIds: [],
    item: null,
    filter: {active: false, desktop: true},
    chat: {
      agentId: "ai-automations-manager",
      agentName: "Manager",
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("automations:getContext", item);
        return {
          path: item?.path,
          label: "Automation",
          // title: item?.file?.other?.displayName || item?.file?.originFilename,
        };
      },
      getActions: async () => {
        return [];
      }
    },
  },
  stores: {
    key: "stores",
    solutionId: 'analyst',
    label: <IconTip key="products" title="Store" placement="right">
      <div><CustomerIcon className="menu-icon"/></div>
    </IconTip>,
    path: "/store",
    icon: <CustomerIcon className="menu-icon"/>,
    children: <ProductsList />,
    sourceIds: [],
    item: null,
    filter: {active: true, desktop: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Analyst",
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("stores:getContext", item);
        return {
          path: item?.path,
          label: "Store",
          // title: item?.file?.other?.displayName || item?.file?.originFilename,
        };
      },
    },
  },
  web: {
    key: "web",
    solutionId: 'analyst',
    label: <IconTip key="web" title="Web" placement="right">
      <div><GlobeIcon className="menu-icon"/></div>
    </IconTip>,
    path: "/web",
    icon: <GlobeIcon className="menu-icon"/>,
    children: null,
    sourceIds: [],
    item: null,
    filter: {active: true, desktop: true},
    chat: {
      agentId: "ai-analyst",
      agentName: "Analyst",
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("source:getContext", item);
        return {
          path: item?.path,
          label: "Web",
          // title: item?.file?.other?.displayName || item?.file?.originFilename,
        };
      },
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "explore a website",
          agentId: "ai-analyst",
        });
        prompts.push({
          text: "search the internet",
          agentId: "ai-analyst",
        });
        return prompts;
      }
    },
  },
  datasets: {
    key: "datasets",
    solutionId: 'analyst',
    label: <IconTip key="analyst" title="Analyst" placement="right">
      <div><DatabaseIcon className="menu-icon"/></div>
    </IconTip>,
    path: "/analyst",
    icon: <DatabaseIcon className="menu-icon"/>,
    children: <Datasets />,
    item: Analyst,
    sourceIds: [],
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-analyst",
      agentName: "Analyst",
      overlayChat: true,
      getCollectionPath: (user, agentId, item) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
      getContext: (item) => {
        console.log("datasets:getContext", item);
        return {
          path: item?.path,
          label: "Dataset",
          title: item.data.name,
        };
      },
      getActions: async (user, {data}) => {
        if (!data?.questions) {
          return [];
        }
        return data.questions.map(({question: text}) => {
          return {text};
        });
      }
    },
  },
  // forms: { // Will be handled under datasets and automations
  //   key: "forms",
  //   solutionId: 'workflows',
  //   label: <IconTip key="quotes" title="Forms" placement="right">
  //     <div><InboxIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/forms",
  //   icon: <InboxIcon className="menu-icon"/>,
  //   children: null, // <QuotesList/>,
  //   sourceIds: [],
  //   item: null,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-manager",
  //     agentName: "Manager",
  //     getCollectionPath: (user, agentId) => `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("quotes:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Form",
  //       };
  //     },
  //   },
  // },
};

/** @typedef {('activity'|'analyst'|'product'|'workflows')} dashboard.SolutionId */

/**
 * @typedef {object} dashboard.SolutionConfig
 * @property {dashboard.SolutionId} key
 * @property {string} label
 * @property {string} agentName
 * @property {string} defaultService
 */

/** @typedef {Object<dashboard.SolutionId, dashboard.SolutionConfig>} dashboard.solutionsConfig */
const solutions = {
  activity: {
    key: "activity",
    label: "Activity",
    agentName: "Assistant",
    defaultService: "progress",
    chat: {
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "summarize my latest activity",
        });
        return prompts;
      }
    },
  },
  analyst: {
    key: "analyst",
    label: "Analyst",
    agentName: "Analyst",
    defaultService: "datasets",
    chat: {
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "About Analyst",
        });
        prompts.push({
          text: "Analyze dataset",
        });
        prompts.push({
          text: "Explore a website",
        });
        prompts.push({
          text: "search the internet",
        });
        return prompts;
      }
    },
  },
  workflows: {
    key: "workflows",
    label: "Workflows",
    agentName: "Manager",
    defaultService: "automations",
    chat: {
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "About automations",
        });
        prompts.push({
          text: "create an automation",
        });
        return prompts;
      }
    },
  },
}
export const getSolutions = () => solutions;
export const getServices = () => services;

/**
 * @summary Chat App Agents
 * @description These agentIds are handled by chat apps and excluded from the primary stream (chat feedback)
 * @type {{emailWriter: {agentId: string}}}
 */
export const chatAppAgents = {
  emailWriter: {
    agentIds: ["ai-email-body-writer"], // ai-email-body-writer
  },
  // userTaskProgress: {
  //   agentIds: ["ai-progress"],
  // },
}

export const chatAppAgentIds = Object
.values(chatAppAgents)
  .map(({agentIds = []}) => agentIds)
.flat();
