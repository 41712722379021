import React from "react";
import PropTypes from "prop-types";
import ContactInfoCard from "./ContactInfoCard";
import {useSelector} from "react-redux";
import {Divider, Empty, List} from "antd";
import ContactsListItem from "./ContactsListItem";
import {AppContext} from "@web/hooks/context";
import Toggle from "./Toggle";
import ItemData from "@web/ui/containers/ItemData";

ContactExtra.propTypes = {
  path: PropTypes.string,
  contact: PropTypes.object,
};

function ContactExtra(props) {
  const [, user] = React.useContext(AppContext);
  const memberUserIdList = useSelector((state) => {
    console.log("DashboardExtra", state.Content);
    return state.Content.activity?.[props.path]?.membersList;
  });

  if (!props.path) {
    return null;
  }

  return <div className="space space-col" style={{width: '100%', minHeight: (memberUserIdList * 60) + 300}}>
    <ContactInfoCard path={props.path} contact={props.contact} />
    <Divider className="small-top small-bottom"/>
    <List
      className="list-item-no-divider"
      dataSource={(memberUserIdList || []).filter((memberUserId) => memberUserId !== props.path.split("/").pop())}
      renderItem={(memberUserId) => <Toggle
        hideTriggerOnExpand
        showExpandCaret
        trigger={
          <ContactsListItem
            key={memberUserId}
            path={["user", user.uid, "contacts", memberUserId].join("/")}
            onClick={(data, e) => {
              console.log("onClick");
            }}
          />}
        >
        <ItemData
          path={["user", user.uid, "contacts", memberUserId].join("/")}
          component={ContactInfoCard}
          dataField="contact"
        />
      </Toggle>}
      locale={{
        emptyText: <div className="space space-center-y">
          <Empty
            description=""
            imageStyle={{display: 'none'}}
          />
        </div>,
      }}
    />
    {/*{memberUserIdList?.length && memberUserIdList.map((memberUserId) => <span key={memberUserId}>{memberUserId}</span>)}*/}
  </div>
}

export default ContactExtra;
