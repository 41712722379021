import React, {useCallback, useContext} from "react";
import {AppContext} from '@web/hooks/context'
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";

function ServiceData(props) {
  const dispatch =
    useDispatch();

  const activeServiceId =
    useSelector((state) =>
      state.App.activeServiceId);

  const [, user] =
    useContext(AppContext);

  const list =
    useSelector(
      (state) =>
        state.Content?.activity?.[props.serviceId]?.list);

  const loadContent =
    React.useCallback(() => {
      console.log("ServiceData:loadContent:", activeServiceId);
      !!activeServiceId &&
      dispatch(
        contentActions
        .loadContent({serviceId: activeServiceId}))
    }, [activeServiceId, dispatch]);

  const pageNext = useCallback(() =>
    dispatch(contentActions.pageContentNext()),
    [dispatch]);

  React.useEffect(() => {
    if (!user) {
      return
    }
    loadContent()
  }, [])

  if (!user?.uid) {
    return null;
  }

  return React.createElement(
    props.component,
    {list, pageNext},
  );
}

export default ServiceData;
