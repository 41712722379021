import actions from "./actions";

/**
 * @typedef {object} State.Content.Context
 * @property {Record<string, web.client.ContextPayload>|null} context
 * @property {string} filter
 */

/**
 * @typedef {object} State.Content
 * @property {web.client.Content | null} content
 * @property {Record<string, State.Content.Context>|null} items
 * @property {web.client.ContentEvents | null} events
 * @property {models.Base.Members | null} members
 * @property {web.client.ContentData | null} data
 * @property {Record<string, omit<web.client.ContentEventsMap, "events">>} activity
 * @property {[string,number]|[]} list
 * @property {Error} error
 */

/** @type {State.Content} */
const initState = {
  content: null,
  lists: {},
  activity: null,
  events: null,
  members: null,
  data: null,
  items: null,
  error: null,
};

/**
 * @summary Reducer for activity
 * @function reducer
 * @param {State.Content} state
 * @param {object} action
 * @param {string} action.type
 * @param {string} [action.path]
 * @param {web.client.ContentData} [action.data]
 * @param {Record<string, omit<web.client.ContentEventsMap, "events">>} [action.activity]
 * @param {web.client.ContentEvents} [action.events]
 * @param {models.Base.Members} [action.members]
 * @param {web.client.ContextPayload} [action.payload]
 * @param {Error} [action.error]
 * @param {string} [action.filter]
 * @return {State.Content}
 */
export default function reducer(
  state = initState, {
    type,
    path,
    data = null,
    activity = null,
    events = null,
    members = null,
    serviceId,
    payload,
    filter,
    showFilter,
    error,
  }) {
  switch (type) {
    case actions.SET_SHOW_FILTER:
      return {
        ...state,
        showFilter: !!showFilter,
        activity: {
          ...state.activity || null,
          filter: showFilter ?
            state.activity?.filter || null :
            null,
        },
      };
    case actions.LOAD_CONTENT_SUCCESS: {
      console.log("reducer:LOAD_CONTENT_SUCCESS:", {data, events, members, activity, serviceId});
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        events: {
          ...state.events,
          ...events,
        },
        activity: {
          ...state.activity,
          ...activity,
          [serviceId]: {
            ...state.activity?.[serviceId] || null,
            list: [...state.activity?.[serviceId]?.list || [], ...activity?.[serviceId]?.list || []],
          }
        },
        members: {
          ...state.members,
          ...members,
        },
        error: null,
      };
    }
    case actions.LOAD_CONTEXT_SUCCESS:
      // const {content, ...rest} = payload;
      console.log("LOAD_CONTEXT_SUCCESS:", {data, events, activity, members});
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        events: {
          ...state.events,
          ...events,
        },
        activity: {
          ...state.activity,
          ...activity,
        },
        members: {
          ...state.members,
          ...members,
        },
        // content: {
        //   ...state.content || null,
        //   [path]: content,
        // },
        // items: {
        //   ...state.items || null,
        //   [path]: rest,
        // },
        error: null,
      };
    case actions.LOAD_CONTEXT_ERROR:
      return {
        ...state,
        items: {
          ...state.items || null,
          [path]: {
            ...(state.items && state.items[path]) || null,
            error,
          },
        },
        error,
      };
    case actions.SET_CONTEXT_FILTER:
      return {
        ...state,
        items: {
          ...state.items,
          [path]: {
            ...(state.items && state.items[path]) || null,
            filter,
          },
        },
      };
    default:
      return state;
  }
}
