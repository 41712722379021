import React from "react";
import {Typography, Button, Divider, List, Empty} from "antd";
import PropTypes from "prop-types";
import Icon from "./Icon";
import ContactDetailsListItem from "./ContactDetailsListItem";
import ExternalLink from "./ExternalLink";

ContactDetails.propTypes = {
  contextPath: PropTypes.string,
  activityList: PropTypes.arrayOf(PropTypes.array),
  itemsList: PropTypes.arrayOf(PropTypes.array),
  contact: PropTypes.object,
  onClose: PropTypes.func,
  loadAllContent: PropTypes.func,
};

function ContactDetails(props) {
  if (!props.contact) {
    return null
  }

  const displayName =
    props.contact.name.replaceAll('"', '') || props.contact.email;

  return <section
    style={{position: "relative", maxWidth: '1200px'}}
  >
    <div style={{width: '100%'}}>
      <div className="space space-between space-center-y">
        <div className="space space-center-y">
          <Button
            ghost
            className="icon-btn icon-left"
            onClick={props.onClose}
          >
            <Icon name="XIcon" />
          </Button>
          <Typography.Title level={4}>
            {displayName}
          </Typography.Title>
        </div>
        <div>
          <ExternalLink
            href={`https://${props.contact.domain}/`}
            target="_blank"
            className="lowercase"
            showIcon
          >
            {props.contact?.domain}
          </ExternalLink>
        </div>
      </div>
    </div>
    <div>
      <Divider />
      <List
        loading={!props.itemsList}
        dataSource={props.itemsList || []}
        // loading={!props.activityList}
        // dataSource={props.activityList || []}
        renderItem={([itemPath], index) =>
          <ContactDetailsListItem
            key={itemPath}
            contextPath={props.contextPath}
            itemPath={itemPath}
            // displayName={displayName}
            // name={props.contact.name}
            // email={props.contact.email}
            // contactIcon={contactIcon}
            primary={index === 0}
          />
        }
        locale={{
          emptyText: <Empty
            description={<span className="ai-text">No relevant activity</span>}
            imageStyle={{display: 'none'}}
          >
            <div className="space space-center-x">
              {/*<span className="ai-texts">Promotional low-relevance content is ignored.</span>*/}
              <Button
                ghost
                onClick={props.loadAllContent}
              >
                <span className="muted font-thin underline">Load promotional low-relevance content</span>
              </Button>
            </div>
            {props.empty}
          </Empty>
        }}
      />
    </div>
  </section>
}

export default ContactDetails;
