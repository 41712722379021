import {all, takeEvery, takeLatest, call, put, take, cancelled, fork} from "redux-saga/effects";
import actions from "./actions";
import {Call} from "@twilio/voice-sdk";
import {auth, firestore} from "@web/lib/firebase";
import TwilioVoice from "@web/lib/twilio-voice";
import {eventChannel} from "redux-saga";
import {deletePath, setPath, updatePath, safeKeyForDB, safeValueForDB} from "@web/lib/firestore.db";
import now from "lodash/now.js";

const twilioVoice = TwilioVoice();

/** @type {Map<string, Call>} */
const calls = new Map();
const callMembers = new Map();

const listenTo = (events = [], target) => {
  return eventChannel((emit) => {
    const unsubscribes = events.map(({event, success, error, ...rest }) => {
      const listener = (e) => {
        console.log('********** Events Received:', {event, success, e, rest});
        switch (true) {
          case e instanceof Call: {
            const callSid = e.parameters.CallSid;
            // console.log("listenTo:eventChannel:listener:call:", {call: e});
            calls.set(callSid, e);
            emit({
              payload: {
                ...rest,
                callSid,
                direction: e.direction,
                status: e.status(),
                member: success === actions.VOICE_CALL_ACCEPTED ?
                  (!!e.customParameters && e.customParameters.has("To") && e.customParameters.get("To") || "") :
                  e.direction === 'INCOMING' ? e.parameters.From : e.parameters.To,
              },
              type: success,
            });
            break;
          }
          case e instanceof Error: {
            emit({
              payload: {
                message: e.message,
                explanation: e.explanation,
                ...rest,
              },
              type: success,
            });
            break;
          }
          default: {
            emit({
              payload: {e, ...rest},
              type: success,
            });
          }
        }
        // case (e instanceof Call): {
        //   const callSid = e.parameters.CallSid;
        //   // console.log("listenTo:eventChannel:listener:call:", {call: e});
        //   calls.set(callSid, e);
        //   emit({
        //     payload: {
        //       ...rest,
        //       callSid,
        //       direction: e.direction,
        //       status: e.status(),
        //       member: success === actions.VOICE_CALL_ACCEPTED ?
        //         (!!e.customParameters && e.customParameters.has("To") && e.customParameters.get("To") || "") :
        //         e.direction === 'INCOMING' ? e.parameters.From : e.parameters.To,
        //     },
        //     type: success,
        //   });
        // } else {
        //   emit({
        //     payload: {e, ...rest},
        //     type: success,
        //   });
        // }
      };
      // console.log("subscribing to Twilio", event)
      target.on(event, listener);
      return {event, listener};
    });

    return () => {
      unsubscribes.forEach(({event, listener}) => {
        console.log("unsubscribing from Twilio", event);
        target.off(event, listener);
      });
    };
  });
};

function* deviceChannel(events = [], device) {
  const listeners = yield call(listenTo, events, device);
  try {
    while (device) {
      const result = yield take(listeners);
      yield put(result);
    }
  } finally {
    console.log("deviceChannel:closing-listeners.");
    if (yield cancelled() || !device) {
      listeners.close();
    }
  }
}

function* callChannel(events = [], target) {
  const voiceCall = target instanceof Call ? target : calls.get(target);
  const listeners = yield call(listenTo, events, voiceCall);
  try {
    // || (target && (target instanceof Call && (target.status() !== 'disconnected' && target.status() !== 'closed')))
    while (voiceCall) {
      const result = yield take(listeners);
      yield put(result);
    }
  } finally {
    console.log("callChannel:closing-listeners");
    if (yield cancelled() || !voiceCall) {
      console.log("callChannel:closed-listeners");
      listeners.close();
    }
  }
}

function* addDeviceEventListeners(device) {
  const events = [
    {event: 'error', success: actions.VOICE_DEVICE_ERROR, key: "device-error"},
    {event: 'incoming', success: actions.VOICE_DEVICE_INCOMING, key: "incoming"}, // an inbound call
    {event: 'registered', success: actions.VOICE_DEVICE_REGISTERED, key: "registered"},
    {event: 'registering', success: actions.VOICE_DEVICE_REGISTERING, key: "registering"},
    {event: 'unregistered', success: actions.VOICE_DEVICE_UNREGISTERED, key: "offline"},
  ];
  yield call(deviceChannel, events, device);
}

function* addCallEventListeners(target) {
  console.log("addCallEventListeners", target);
  const callSid = typeof target === 'string' ? target : ""
  const voiceCall = target instanceof Call ? target : calls.get(callSid);

  if (!voiceCall) {
    console.log("addCallEventListeners", "Call not found", voiceCall);
    return;
  }
  const events = [
    {event: 'error', success: actions.VOICE_CALL_ERROR, key: "call-error", callSid},
    {event: 'accept', success: actions.VOICE_CALL_ACCEPTED, key: "accept", callSid},
    {event: 'cancel', success: actions.VOICE_CALL_CANCELLED, key: "cancel", callSid},
    {event: 'ignore', success: actions.VOICE_CALL_IGNORED, key: "ignore", callSid},
    {event: 'disconnect', success: actions.VOICE_CALL_DISCONNECTED, key: "disconnect", callSid},
    {event: 'reconnecting', success: actions.VOICE_CALL_RECONNECTING, key: "reconnecting", callSid},
    {event: 'mute', success: actions.VOICE_CALL_MUTED, key: "mute", callSid},
    {event: 'reject', success: actions.VOICE_CALL_REJECTED, key: "reject", callSid},
    {event: 'ringing', success: actions.VOICE_CALL_RINGING, key: "ringing", callSid}, // outbound calls only
    {event: 'warning', success: actions.VOICE_CALL_WARNING, key: "warning", callSid},
    {event: 'closed', success: actions.VOICE_CALL_CLOSED, key: "closed", callSid},
    {event: 'connecting', success: actions.VOICE_CALL_CONNECTING, key: "connecting", callSid},
  ];
  yield call(callChannel, events, target);
}

function logNewCall(callSid) {
  console.log("saga:logNewCall:", callSid);
  if (!calls.has(callSid)) {
    return;
  }

  console.log("logNewCall:callSid:", calls.get(callSid));
  const voiceCall = calls.get(callSid);
  const memberPhoneNumber = voiceCall.direction === 'INCOMING' ? voiceCall.customParameters.get("From") : voiceCall.customParameters.get("To");

  return setPath(["user", auth.currentUser.uid, "voice-logs", callSid], {
    ...safeValueForDB({
      direction: voiceCall.direction,
      parameters: voiceCall.parameters,
      options: voiceCall.customParameters.get("options"),
      memberPhoneNumber,
      memberInfo: callMembers.get(memberPhoneNumber),
      status: voiceCall.status(),
      outboundConnectionId: voiceCall.outboundConnectionId,
      date: now(),
      createdTs: now(),
    }),
  }, true);
}

/**
 * Initialize voice
 * @return {Generator<*, void, *>}
 */
function* register() {
  if (!auth.currentUser?.uid) {
    return;
  }

  const {device, identity} = yield call(twilioVoice.getDevice);
  console.log("register", device, identity);
  if (!device) {
    return;
  }

  if (device.state === 'registered' || device.state === 'registering') {
    return;
  }

  yield fork(addDeviceEventListeners, device);
  yield call(twilioVoice.register);
}

/**
 * Connect a call
 * @param {object} action
 * @param {string} action.callSid
 * @return {Generator<*, void, *>}
 */
function* acceptCall({callSid}) {
  console.log("saga:acceptCall:", callSid);
  if (!auth.currentUser?.uid) {
    return;
  }
  const voiceCall = calls.get(callSid);
  if (!voiceCall) {
    return;
  }
  // console.log("acceptCall:voiceCall", voiceCall);
  voiceCall.accept();
  // yield call(voiceCall.accept);
}

/**
 * Connect a call
 * @param {object} action
 * @param {object} action.params
 * @return {Generator<*, void, *>}
 */
function* connectCall({params = null, memberInfo = null}) {
  console.log("saga:connectCall", params, memberInfo);
  if (!auth.currentUser?.uid) {
    return;
  }

  callMembers.set(params.To, memberInfo);
  const outgoingCall = yield call(twilioVoice.connect, params);
  // const callSid = outgoingCall.parameters.CallSid;
  // console.log("connectCall:callSid:call:", callSid, outgoingCall);
  // calls.set(callSid, outgoingCall);
  // listenTo(['accept'], outgoingCall);
  // outgoingCall.on('accept', function*(e) {
  //   console.log("connectCall:accept", e);
  yield fork(addCallEventListeners, outgoingCall);
  // yield call(logNewCall, outgoingCall);
  // });
  // yield call(twilioVoice.connect, params);
  // /** @type {Call} */
  // const voiceCall = calls.get(params.callSid);
  // if (!voiceCall) {
  //   return;
  // }
  // const record = getState().Voice.record;
  // const updatedCall = call.direction === 'INCOMING' ?
  //   yield call(voiceCall.accept) :
  //   yield call(twilioVoice.connect, params);
    // dispatch({
    //   type: actions.VOICE_CALL_ACCEPTED,
    //   params: {
    //     ...params,
    //     options: {
    //       record,
    //     },
    //   },
    // }) :
    // params?.To &&
    // dispatch({
    //   type: actions.VOICE_CALL_CONNECT,
    //   params: {
    //     ...params,
    //     options: {
    //       record,
    //     },
    //   },
    // });
  // if (!params?.To) {
  //   return;
  // }
  // const twilioVoice = TwilioVoice();
  // const outgoingCall = yield call(twilioVoice.connect, params);
  // yield put({
  //   type: actions.VOICE_CALL_CONNECTED,
  //   payload: {args: [outgoingCall]},
  // });
  // yield fork(addCallEventListeners, outgoingCall);
  // yield call(logNewCall, outgoingCall);
}

function* incomingCall({payload}) {
  console.log(">>>>>>saga:incomingCall:payload:", payload);
  yield fork(addCallEventListeners, payload.callSid);
  yield put({
    type: actions.VOICE_CALL_INCOMING,
    payload,
  });
  yield call(logNewCall, payload.callSid);
}

function* acceptedCall({payload}) {
  console.log(">>>>>>>saga:acceptedCall:payload:", payload);
  yield fork(addCallEventListeners, payload.callSid);
  yield put({
    type: actions.VOICE_CALL_INCOMING_ACCEPTED,
    payload,
  });
  yield call(logNewCall, payload.callSid);
}

function* rejectCall({callSid}) {
  const voiceCall = calls.get(callSid);
  if (!voiceCall) {
    return;
  }
  voiceCall.reject();
}

function* disconnectCall({callSid}) {
  const voiceCall = calls.get(callSid);
  if (!voiceCall) {
    return;
  }
  voiceCall.disconnect();
}

function* disconnectAll() {
  twilioVoice.getDevice()
  .then(({device} = {}) => device && device.disconnectAll());
}

function* toggleMuted() {
  calls.forEach((voiceCall) => {
    voiceCall &&
    voiceCall.mute(!voiceCall.isMuted())
  });
}

function* clearCall({callSid}) {
  console.log("saga:clearCall:", callSid);
  calls.delete(callSid);
  yield put({
    type: actions.VOICE_CALL_CLEARED,
    payload: {
      callSid,
    },
  });
}

// function* onCallDisconnected({callSid}) {
//   const voiceCall = calls.get(callSid);
//   console.log("saga:onCallDisconnected:", callSid, voiceCall);
//   const sessionId = voiceCall.direction === 'INCOMING' ?
//     voiceCall.parameters.From :
//     voiceCall.parameters.To;
//
//   deletePath(["user", auth.currentUser?.uid, "voice-sessions", safeKeyForDB(sessionId)]);
// }

export default function* rootSaga() {
  yield all([
    takeLatest(actions.VOICE_DEVICE_REGISTER, register),
    takeLatest(actions.VOICE_CALL_CONNECT, connectCall),
    takeEvery(actions.VOICE_CALL_DISCONNECT_ALL, disconnectAll),
    takeEvery(actions.VOICE_CALL_TOGGLE_MUTED, toggleMuted),
    takeEvery(actions.VOICE_DEVICE_INCOMING, incomingCall),
    takeLatest(actions.VOICE_CALL_ACCEPTED, acceptedCall),
    takeEvery(actions.VOICE_CALL_ACCEPT, acceptCall),
    takeEvery(actions.VOICE_CALL_REJECT, rejectCall),
    takeEvery(actions.VOICE_CALL_DISCONNECT, disconnectCall),
    takeEvery(actions.VOICE_CALL_CLOSED, clearCall),
    // takeEvery(actions.VOICE_CALL_DISCONNECTED, onCallDisconnected),
  ]);
}
