import React from "react";
import PropTypes from "prop-types";
import MarkdownTimeline from "./MarkdownTimeline";
import useChatStreamListener from "@web/hooks/useChatStreamListener";
import ChatProgressData from "@web/ui/containers/ChatProgressData";
import ScribeWorkflowPrompt from "./ScribeWorkflowPrompt";
import DraftEditorManager from "./DraftEditorManager";
import {useSelector} from "react-redux";
import {chatAppAgentIds} from "@oven/app-thescribe/src/dashboard.config";
import UserTaskProgressManager from "./UserTaskProgressManager";
import {Avatar, Comment} from "antd";
import {FeatherIcon} from "@web/assets/icons";
import aiActions from "@web/redux/ai/actions";
import StreamingDraftListener from "./StreamingDraftListener";

ChatFeedback.propTypes = {
  agentId: PropTypes.string.isRequired,
  promptText: PropTypes.string,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
};

function ChatFeedback(props) {
  const activeDraftPath =
    useSelector((state) =>
      state.AI.draft?.draftPath);

  const activeUserTaskPath =
    useSelector((state) =>
      state.AI.userTask?.path);

  const [chatStream, chatPath] =
    useChatStreamListener(props.agentId);

  const [collapsed, setCollapsed] =
    React.useState(false);

  React.useEffect(() => {
    setCollapsed(false);
  }, [props.promptText]);

  const toggleCollapse = React
  .useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return <div className="chat-feedback-view">
    {(props.promptText || chatStream?.prompt?.text) &&
      <ScribeWorkflowPrompt
        text={props.promptText || chatStream?.prompt?.text}
        onClick={toggleCollapse}
      />
    }
      {!collapsed &&
        chatStream?.answers?.length &&
        <section className="space space-end-y">
          <div className="space space-col">
            <Comment
              content={
              chatStream.answers.map((answer) =>
                !!answer.text &&
                !!answer.toolCalls &&
                !chatAppAgentIds.includes(answer.agentId) &&
                <MarkdownTimeline
                  key={answer.generated}
                  markdown={answer.text}
                />)
              }
              avatar={<Avatar icon={<FeatherIcon />} size="large" />}
            />
          </div>
        </section>
      }
    {!!activeUserTaskPath &&
      <div style={{width: '990px', maxWidth: '100%', margin: '0 auto 10px'}}>
        <UserTaskProgressManager
          agentId={props.agentId}
          path={activeUserTaskPath}
          onAddContextPath={props.onAddContextPath}
          onRemoveContextPath={props.onRemoveContextPath}
        />
      </div>
    }
    {!!activeDraftPath ?
      <div style={{width: '990px', maxWidth: '100%', margin: '0 auto 10px'}}>
        <DraftEditorManager
          agentId={props.agentId}
          draftPath={activeDraftPath}
          onAddContextPath={props.onAddContextPath}
          onRemoveContextPath={props.onRemoveContextPath}
          onClearActiveEmailDraft={aiActions.clearActiveEmailDraft}
        />
      </div> :
      !!chatStream && <StreamingDraftListener chatStream={chatStream} />
    }
    {!!chatPath && <ChatProgressData path={chatPath} />}
  </div>
}

export default ChatFeedback;
