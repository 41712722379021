const actions = {
  LOAD_CONTENT: "LOAD_CONTENT",
  LOAD_CONTENT_SUCCESS: "LOAD_CONTENT_SUCCESS",
  LOAD_CONTENT_ERROR: "LOAD_CONTENT_ERROR",
  LOAD_CONTEXT: "LOAD_CONTEXT",
  LOAD_CONTEXT_SUCCESS: "LOAD_CONTEXT_SUCCESS",
  LOAD_CONTEXT_ERROR: "LOAD_CONTEXT_ERROR",
  FILTER_CONTENT: "FILTER_CONTENT",
  SET_ACTIVITY_FILTER: "SET_ACTIVITY_FILTER",
  SET_CONTEXT_FILTER: "SET_CONTEXT_FILTER",
  SET_SHOW_FILTER: "SET_SHOW_FILTER",
  LOAD_CONTACTS: "LOAD_CONTACTS",
  LOAD_CONTACTS_SUCCESS: "LOAD_CONTACTS_SUCCESS",
  PAGE_CONTACTS: "PAGE_CONTACTS",
  PAGE_CONTACTS_SUCCESS: "PAGE_CONTACTS_SUCCESS",
  setShowFilter: (showFilter) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SHOW_FILTER,
        showFilter,
      });
    };
  },
  loadContext: (path, params = null) => {
    return (dispatch) => {
      console.log("loadContext", {path, params});
      dispatch({
        type: actions.LOAD_CONTEXT,
        path,
        params,
      });
    };
  },
  loadContent: (params) => {
    return (dispatch) => {
      console.log("loadContent", params);
      dispatch({
        type: actions.LOAD_CONTENT,
        params: {
          ...params || null,
        },
      });
    };
  },
  filterActivity: (params) => {
    return (dispatch, getState) => {
      const q = params?.q?.trim();
      q ? dispatch(actions.setShowFilter(true)) : dispatch(actions.setShowFilter(false));

      if (q?.length < 3) {
        return;
      }

      dispatch({
        type: actions.FILTER_CONTENT,
        params: {
          q,
          searchOptions: {
            ...getState().Content?.activity?.filter?.searchOptions || null,
            // page: params.page,
          },
        },
      });
    };
  },
  pageContentNext: () => {
    return (dispatch, getState) => {
      console.log("pageContentNext");
      const activeServiceId = getState().App?.activeServiceId;
      const listLength = getState().Content?.activity?.[activeServiceId]?.list?.length;
      const params = {
        serviceId: activeServiceId,
        // page: getState().Content?.activity?.[activeServiceId]?.page + 1,
        startAfter: listLength && getState().Content?.activity?.[activeServiceId]?.list[listLength - 1][1],
      };
      dispatch(actions.loadContent(params));
    };
  },
  // pageContentNext: () => {
  //   return (dispatch, getState) => {
  //     console.log("pageContentNext");
  //     const activeServiceId = getState().App?.activeServiceId;
  //     const isFilter = getState().Content?.showFilter;
  //
  //     const activityKey =
  //       isFilter ?
  //         "filter" :
  //         activeServiceId;
  //
  //     const params = {
  //       serviceId: activeServiceId,
  //       page: getState().Content?.activity?.[activityKey]?.page + 1,
  //     };
  //
  //     isFilter &&
  //     (params.q = getState().Content?.activity?.filter?.q ?? "");
  //
  //     dispatch(actions.filterActivity(params));
  //   };
  // },
  setActivityFilter: (filter) => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_ACTIVITY_FILTER,
        filter,
      });
    };
  },
  loadAllContent: (params) => {
    return () => {
      console.log("loadAllContent", params?.path);
    };
  }
};

export default actions;
export const getKeyForFilter = (activityFilter) => {
  switch (activityFilter) {
    case "urgent":
      return "isUrgent";
    case "payments":
      return "isPaymentRelated";
    case "meetings":
      return "isMeetingRelated";
    case "deliveries":
      return "isDeliveryRelated";
    case "todos":
      return "requiresAction";
    case "complaints":
      return "isComplaintRelated";
    case "sales":
      return "isSaleRelated";
    case "support":
      return "isSupportRelated";
    case "products":
      return "isProductRelated";
    case "notifications":
      return "isNotification";
  }
}
