import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon} from "@web/assets/icons";
import voiceActions from "@web/redux/voice/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Tooltip} from "antd";

VoiceCallButton.propTypes = {
  countryCode: PropTypes.string,
  to: PropTypes.string.isRequired,
  name: PropTypes.string,
  contactPath: PropTypes.string,
  numberType: PropTypes.string,
  size: PropTypes.oneOf(["small", "middle", "large"]),
};

function VoiceCallButton(props) {
  const dispatch = useDispatch();
  const registered = useSelector((state) => state.Voice.registered);
  const connectCall = (params, memberInfo) => dispatch(voiceActions.connectCall(params, memberInfo));
  const onClickConnect = () => {
    const callParams = {To: (props.countryCode || "+1") + props.to};
    const memberInfo = {
      name: props.name,
      contactPath: props.contactPath,
      numberType: props.numberType,
    };
    connectCall(callParams, memberInfo);
  }

  /**
   * Checks if the given value is valid as phone number
   * @param {string} number
   * @return {boolean}
   */
  const isValidPhoneNumber = (number) => {
    return /^[\d+\-() ]+$/.test(number);
  }

  const disableCallButton = () => {
    return (!registered || !isValidPhoneNumber(props.to));
  }

  if (props.to?.length < 10) {
    return null;
  }

  return <Tooltip title="Call" trigger={['hover']} placement="bottom">
    <Button
      ghost
      disabled={disableCallButton()}
      onClick={onClickConnect}
      size={props.size || "large"}
      shape="circle"
      className="icon-btn icon-right"
      style={{marginTop: '-8px'}}
    >
      <PhoneCallIcon
        className="green-icon"
        style={{position: 'relative', top: '2px'}}
      />
    </Button>
  </Tooltip>
}

export default VoiceCallButton;
