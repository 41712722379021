import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector, } from "react-redux";
import {generateID, setPath} from "@web/lib/firestore.db";
import now from "lodash/now.js";
import aiActions from "@web/redux/ai/actions";

EmailAction.propTypes = {
  action: PropTypes.string.isRequired,
  emailPath: PropTypes.string,
  toEmails: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
};

function EmailAction(props) {
  const {action, emailPath} = props;
  const dispatch = useDispatch();

  /** @type {models.EmailMessage.Data} */
  const parentEmailMessage =
    useSelector((state) =>
      state.Content.data?.[emailPath]);

  const onClick = () => {
    if (!emailPath && !props.toEmails) {
      throw new Error("emailPath prop is required");
    }

    const draftPath = [
      "user", parentEmailMessage.userId, "email-drafts", generateID(),
    ].join("/");

    const getDraftDistro = () => {
      switch (action) {
        case "reply": {
          const replyTo =
            parentEmailMessage.replyTo || parentEmailMessage.from;

          return replyTo.email === parentEmailMessage.owner.email ?
            parentEmailMessage.to :
            [replyTo];
        }
        case "reply-all":
          return [
            parentEmailMessage.from,
            ...parentEmailMessage.to,
          ];
        case "forward":
          return props.toEmails || [];
        default:
          return props.toEmails || [];
      }
    }

    const draftData = {
      action,
      toEmails: getDraftDistro(),
      ccEmails: [],
      createdTs: now(),
      date: now(),
    };

    emailPath &&
    (draftData.parentEmailPath = emailPath);

    return setPath(draftPath, draftData)
    .then(() => dispatch(aiActions.setActiveEmailDraft(draftPath)));
  }

  return <div
    style={props.style}
    onClick={onClick}
  >
    {props.children}
  </div>
}

export default EmailAction;
